<template>
  <Modal @on-close="emit('onClose')">
    <Command v-bind="{ ...props, ...emitAsProps, ...attrs }">
      <template #option="{ item, isActive }">
        <slot name="option" :item="item" :is-active="isActive"></slot>
      </template>
    </Command>
  </Modal>
</template>

<script setup lang="ts" generic="T">
import type { Props as CommandProps } from "~/components/ui/command/Command.vue";
import type { Emits as ModalEmits } from "~/components/modal/Modal.vue";
import { useEmitAsProps } from "radix-vue";

export type Props<T> = CommandProps<T>;
export type Emits = ModalEmits;

const props = defineProps<Props<T>>();

const emit = defineEmits<Emits>();

const emitAsProps = useEmitAsProps(emit);

const attrs = useAttrs();
</script>

<style scoped lang="scss"></style>
