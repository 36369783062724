<template>
  <SuperCombobox
    v-model:search-term="searchTerm"
    :model-value="[]"
    :options="optionsAfterSearch"
    :placeholder="placeholder"
    style="width: 512px; margin-top: 64px"
    @on-select="
      (item) => {
        item.action();
      }
    "
  >
    <template #option="{ item, isActive }">
      <slot name="option" :item="item" :is-active="isActive">
        <CommandItem :item="item" :is-active="isActive" />
      </slot>
    </template>
  </SuperCombobox>
</template>

<script setup lang="ts" generic="T">
import Fuse from "fuse.js";

export type ContextMenuOption<T = object> = { label: string; icon?: string; emoji?: string; action: () => void } & T;

export type Props<T> = { options: ContextMenuOption<T>[]; placeholder?: string };

const props = withDefaults(defineProps<Props<T>>(), { placeholder: "Sélectionner une action" });

const searchTerm = ref("");

const searchFilter = function (contextMenuOptions: ContextMenuOption<T>[], searchTerm: string) {
  if (!searchTerm) return contextMenuOptions;

  const fuse = new Fuse(contextMenuOptions, {
    keys: ["label"],
    threshold: 0.5,
  });

  return fuse.search(searchTerm).map((result) => result.item);
};

const optionsAfterSearch = computed<ContextMenuOption<T>[]>(() => searchFilter(props.options, searchTerm.value));
</script>

<style scoped></style>
